<template>
  <div>
    <h3>
      <b>{{ $t('FAQ.deleteAccount.header') }}</b>
    </h3>
    <hr />
    <div v-html="$t('FAQ.deleteAccount.q1')"></div>
    <ol>
      <li v-html="$t('FAQ.deleteAccount.a1m1')"></li>
      <br />
      <li v-html="$t('FAQ.deleteAccount.a1m2')"></li>
      <br />
      <li v-html="$t('FAQ.deleteAccount.a1m3')"></li>
    </ol>
    <hr />
    <div v-html="$t('FAQ.deleteAccount.a2')"></div>
    <hr />
    <div v-html="$t('FAQ.deleteAccount.a3')"></div>
    <ol>
      <li><div v-html="$t('FAQ.deleteAccount.s1')"></div></li>
      <li><div v-html="$t('FAQ.deleteAccount.s2')"></div></li>
      <li><div v-html="$t('FAQ.deleteAccount.s3')"></div></li>
      <li><div v-html="$t('FAQ.deleteAccount.s4')"></div></li>
      <li><div v-html="$t('FAQ.deleteAccount.s5')"></div></li>
      <li><div v-html="$t('FAQ.deleteAccount.s6')"></div></li>
      <li><div v-html="$t('FAQ.deleteAccount.s7')"></div></li>
    </ol>
    <hr />
  </div>
</template>

<script>
export default {};
</script>

<style></style>
